export const EMOJIS = {
  BAITY_DEDO:
    'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_4af1a8bb61da4c749fd872914cdab147/default/dark/3.0',
  BAITY_LOVE:
    'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_59f88219b0514aa59bc3227d5b1f699b/default/dark/3.0',
  BAITY_MEME:
    'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_966fd70f3d014eb5aeb766167e77cfca/default/dark/3.0',
  BAITY_DORITOS:
    'https://static-cdn.jtvnw.net/emoticons/v2/emotesv2_0241b71e51f24be09d41b6528e4b9647/default/dark/3.0 ',
}
