export const LayoutTable = [
  {
    label: '3x3',
    value: '3',
  },
  {
    label: '4x4',
    value: '4',
  },
  {
    label: '5x5',
    value: '5',
  },
  {
    label: '6x6',
    value: '6',
  },
]
